import { SystemProps, Flex, Box } from '@storyofams/react-ui'
import { Image as ToolkitImage } from '@storyofams/storyblok-toolkit'
import { Text, Button, Heading, FixedRatio } from '~components'
import { HeroTexture } from './svg/HeroTexture'

type HeroV2Props = {
  content: {
    image?: {
      filename?: string
      alt?: string
    }
    image_placement?: string
    title?: string
    description?: string
    button_text?: string
    button_link?: {
      cached_url?: string
    }
    button2_text?: string
    button2_link?: {
      cached_url?: string
    }
    background_color?: {
      color?: string
    }
    text_block_footer_color?: {
      color?: string
    }
    show_text_block_footer: boolean
    font_color?: {
      color?: string
    }
    text_block_footer_images?: Array<{
      _uid?: string
      image?: {
        filename?: string
        alt?: string
      }
    }>
  }
  first?: boolean
} & SystemProps

export const HeroV2 = ({ content }: HeroV2Props) => {
  //console.log('Hero_V2 => ', content)
  let imagePlacement = null

  if (content?.image_placement && content.image_placement == 'left') {
    imagePlacement = 'row-reverse'
  }

  if (content?.image_placement && content.image_placement == 'right') {
    imagePlacement = 'row'
  }

  return (
    <Flex
      width="100%"
      justifyContent="space-between"
      flexDirection={[
        'column-reverse',
        !!imagePlacement ? imagePlacement : 'row',
      ]}
      backgroundColor={
        content?.background_color ? content?.background_color.color : 'auto'
      }
    >
      <Flex width={['100%', '43%']} flexDirection={'column'}>
        <Flex
          m={[4, 7]}
          mr={[4, 10]}
          height={'100%'}
          justifyContent={'center'}
          flexDirection={'column'}
        >
          {!!content?.title && (
            <Heading
              as="h1"
              variant="sh1"
              lineHeight={'130%'}
              letterSpacing={['3.5px']}
              width={'100%'}
              color={
                !!content?.font_color ? content?.font_color.color : 'initial'
              }
            >
              {content?.title}
            </Heading>
          )}
          {!!content?.description && (
            <Text
              lineHeight={'130%'}
              mt={[2, '36px']}
              whiteSpace="pre-line"
              width={'100%'}
              fontSize={'22px'}
              color={
                !!content?.font_color ? content?.font_color.color : 'initial'
              }
            >
              {content?.description}
            </Text>
          )}
          <Flex
            gap={3}
            mt={['36px']}
            flexDirection={['column', 'column', 'column', 'row']}
          >
            {!!content?.button_text && (
              <Flex width={'100%'}>
                <Button
                  width={['100%', !content?.button2_text ? 'auto' : '100%']}
                  variant="button_type_1"
                  to={content?.button_link?.cached_url}
                >
                  {content?.button_text}
                </Button>
              </Flex>
            )}
            {!!content?.button2_text && (
              <Flex width="100%">
                <Button
                  width={['100%']}
                  variant="button_type_3"
                  to={content?.button2_link?.cached_url}
                >
                  {content?.button2_text}
                </Button>
              </Flex>
            )}
          </Flex>
        </Flex>
        {content?.show_text_block_footer && (
          <Box display={['none', 'flex']} flexDirection={'column'}>
            <FixedRatio width="100%" ratio={[[728, 80]]}>
              <HeroTexture
                background_texture_color={
                  content?.text_block_footer_color?.color
                }
                texture_color={content?.background_color.color}
              />
            </FixedRatio>
            <FixedRatio
              width="100%"
              ratio={[[620, 83]]}
              bg={content?.text_block_footer_color?.color}
            >
              {!!content?.text_block_footer_images && (
                <Flex
                  px={10}
                  height={'100%'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  gap={5}
                >
                  {content?.text_block_footer_images.map(el => (
                    <Box key={el?._uid}>
                      <img
                        width={'100%'}
                        style={{ maxWidth: '200px' }}
                        src={el.image?.filename}
                        alt={el?.image?.alt}
                      />
                    </Box>
                  ))}
                </Flex>
              )}
            </FixedRatio>
          </Box>
        )}
      </Flex>
      <Flex width={['100%', '57%']}>
        {!!content?.image && (
          <FixedRatio
            width="100%"
            ratio={[
              [392, 282],
              [820, 590],
            ]}
          >
            <ToolkitImage
              src={content.image?.filename}
              alt={content.image?.alt}
              fluid={1200}
              width={'100%'}
              height={'100%'}
              fit="cover"
            />
          </FixedRatio>
        )}
      </Flex>
    </Flex>
  )
}
